@font-face {
  font-family: 'Pretendo';
  src: url('../public/Pretendo.ttf') format('truetype');
}

html, body {
  width: 100%;
  height: 100%;
  min-height: 100vh; /*added this top*/
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Press Start 2P', Arial, sans-serif;
  background-image: url('../public/images/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#nav-bar {
  width: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 0;
}

.nav-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.nav-menu li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 15px;
  font-family: 'Press Start 2P', Arial, sans-serif;
}

.nav-menu li a:hover {
  text-decoration: none;
}

.nav-left {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.nav-left li a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-family: 'Press Start 2P', Arial, sans-serif;
  padding: 14px 16px;
}

.nav-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(215, 22, 22);
  font-family: 'Press Start 2P', Arial, sans-serif;
  font-size: 20px;
}

.countdown {
  display: flex;
  gap: 5px;
}

#message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 80px;
}

.message-text-header {
  margin-top: 30px;
  font-size: 50px;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Press Start 2P', Arial, sans-serif;
}

.message-text {
  font-size: 22px;
  color: rgb(255, 255, 255);
  font-family: 'Press Start 2P', Arial, sans-serif;
  text-decoration: none !important;
}

.number-text {
  font-size: 18px;
  font-family: 'Press Start 2P', Arial, sans-serif;
}

@media (max-width: 768px) {
  .nav-menu {
    flex-direction: column;
    margin-right: 0;
    font-size: 14px;
    margin-right: 20px;
  }

  .nav-left li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Press Start 2P', Arial, sans-serif;
    padding: 14px 16px;
  }

  .nav-left {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 20px;
  }

  .nav-center {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(215, 22, 22);
    font-family: 'Press Start 2P', Arial, sans-serif;
    font-size: 14px;
  }

  .countdown {
    display: flex;
    gap: 5px;
  }

  #message-container {
    margin-top: 120px;
  }

  .message-text-header {
    font-size: 30px;
    padding: 5px;
  }

  .message-text {
    font-size: 14px;
    margin-top: -5px;
  }

  .number-text {
    font-size: 14px;
    font-family: 'Press Start 2P', Arial, sans-serif;
  }
}

@media (max-width: 480px) {
  .nav-left li a {
    font-size: 16px;
    padding: 10px 0;
  }

  .nav-menu li a {
    font-size: 14px;
    padding: 8px 0;
  }

  #message-container {
    margin-top: 120px;
  }

  .message-text-header {
    font-size: 24px;
    padding: 5px;
  }

  .message-text {
    font-size: 14px;
    margin-top: -5px;
  }

  .number-text {
    font-size: 14px;
    font-family: 'Press Start 2P', Arial, sans-serif;
  }
}
