html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.landing-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
}
.contract-address {
    width: 100%;
    margin-top: 20px;
    font-size: 1.2rem;
    color: #ff0000;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, -2px -2px 0 #000,
        2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000, -3px -3px 0 #000, 3px -3px 0 #000,
        -3px 3px 0 #000, 3px 3px 0 #000;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
}

/* Existing cursor style */
.cursor {
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-right: 5px;
}

.cursor-small {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.blink {
    animation: blink-animation 1s step-start infinite;
}

@keyframes blink-animation {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}



.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 998;
    pointer-events: none;
}

.logo-container {
    margin-bottom: -50px;
}

.logo-image {
    width: 85%;
}

.content-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.person-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    padding-top: 50px;
    margin-right: 25px;
}

.person-container img {
    width: 450px;
    height: 450px;
}

.menu-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 45%;
    user-select: none;
    padding-top: 50px;
}

.menu-item {
    font-size: 2.3rem;
    margin: 12px 0;
    position: relative;
    display: flex;
    align-items: center;
    color: #ff0000;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, -2px -2px 0 #000,
        2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000, -3px -3px 0 #000, 3px -3px 0 #000,
        -3px 3px 0 #000, 3px 3px 0 #000;
    cursor: pointer;
    user-select: none;
}
.menu-item.telegram-offset {
    margin-left: -6px; /* Adjust the exact value as needed */
  }
/* Modal overlay to cover the whole screen and center content */
.landing-page-modal-text::-webkit-scrollbar {
    width: 12px;
}

.landing-page-modal-text::-webkit-scrollbar-track {
    background: #acacac;
    border-radius: 6px;
}

.landing-page-modal-text::-webkit-scrollbar-thumb {
    background: #615f5f;
    border-radius: 6px;
}

.landing-page-modal-text::-webkit-scrollbar-thumb:hover {
    background: #3b3939;
}

.landing-page-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    user-select: none;
}

.landing-page-modal-content {
    background-size: cover;
    width: 850px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgb(171, 167, 167);
    border-radius: 15px;
}

.landing-page-modal-content.nochillio-monitor {
    background: url("../public/images/nochillio_monitor.png") no-repeat center center;
    background-size: cover;
}

.landing-page-modal-content.nochill-monitor {
    background: url("../public/images/nochill_monitor.png") no-repeat center center;
    background-size: cover;
}

.landing-page-modal-text {
    width: 40%;
    height: 70%;
    color: #ff0000;
    border-radius: 10px;
    overflow-y: auto;
    text-align: center;
    font-size: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    align-items: center;
    text-align: left;
}

.landing-page-modal-text ul {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    margin-left: 8px;
}

.landing-page-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: white;
}

/* Style links inside modal */
.landing-page-modal-header {
    font-size: 35px;
    width: 100%;
    text-align: center;
    margin-top: 0px;
}

.landing-page-modal-text a {
    color: #ff0000;
    text-decoration: none;
    font-size: 25px;
}

.landing-page-modal-text a:visited {
    color: #ff0000;
}

.landing-page-modal-text a:focus,
.landing-page-modal-text a:active {
    color: #ff0000;
}

.landing-page-modal-text ul li,
.landing-page-modal-text ul.sub-list li {
    margin-bottom: 25px;
    font-size: 25px; /* Ensure this matches the size you want */
}

.landing-page-modal-text ul.sub-list {
    margin-top: 10px;
    width: 80%; /* Increase the width of the sublist */
    margin-left: 20px; /* Shift the sublist to the left */
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
    .content-wrapper {
        margin-left: 50px;
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 55%;
        user-select: none;
    }
	.contract-address { 
		font-size: .8em;
	}
    .landing-page-modal-text::-webkit-scrollbar {
        width: 12px;
    }

    .landing-page-modal-text::-webkit-scrollbar-track {
        background: #acacac;
        border-radius: 6px;
    }

    .landing-page-modal-text::-webkit-scrollbar-thumb {
        background: #615f5f;
        border-radius: 6px;
    }

    .landing-page-modal-text::-webkit-scrollbar-thumb:hover {
        background: #3b3939;
    }

    .logo-image {
        width: 80%;
        margin-bottom: 100px;
    }

    .person-container {
        margin-right: 10px;
        margin-left: -50px;
    }

    .person-container img {
        width: 400px;
        height: 400px;
    }

    .menu-item {
        font-size: 2rem;
    }

    .cursor {
        width: 30px;
        height: 30px;
    }

    .landing-page-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .landing-page-modal-content {
        background-size: cover;
        background-color: rgb(133, 128, 128);
        width: 650px;
        height: 425px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid rgb(171, 167, 167);
        border-radius: 15px;
    }

    .landing-page-modal-text {
        width: 70%;
        height: 60%;
        color: #ff0000;
        padding: 20px;
        border-radius: 10px;
        overflow-y: auto;
        text-align: center;
        font-size: 18px;
    }

    /* Remove bullet points from lists inside the modal */
    .landing-page-modal-text ul {
        list-style-type: none;
        padding-left: 0;
        margin-left: 120px;
    }

    .landing-page-modal-close-button {
        position: absolute;
        top: 3px;
        right: 3px;
        background: none;
        border: none;
        font-size: 15px;
        cursor: pointer;
        color: white;
    }

    /* Style links inside modal */
    .landing-page-modal-header {
        font-size: 25px;
    }

    .landing-page-modal-text a {
        color: #ff0000;
        text-decoration: none;
        font-size: 22px;
    }

    .landing-page-modal-text a:visited {
        color: #ff0000;
    }

    .landing-page-modal-text a:focus,
    .landing-page-modal-text a:active {
        color: #ff0000;
    }

	.landing-page-modal-text ul li,
	.landing-page-modal-text ul.sub-list li {
		margin-bottom: 25px;
		font-size: 22px;
	}

    .landing-page-modal-text ul.sub-list {
        margin-top: 10px;
    }

}

@media (max-width: 992px) {
    /* Modal overlay to cover the whole screen and center content */
    .content-wrapper {
        margin-left: 50px;
    }
	.contract-address { 
		font-size: .5em;
	}
    .menu-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 55%;
        user-select: none;
    }

    .landing-page-modal-text::-webkit-scrollbar {
        width: 12px;
    }

    .landing-page-modal-text::-webkit-scrollbar-track {
        background: #acacac;
        border-radius: 6px;
    }

    .landing-page-modal-text::-webkit-scrollbar-thumb {
        background: #615f5f;
        border-radius: 6px;
    }

    .landing-page-modal-text::-webkit-scrollbar-thumb:hover {
        background: #3b3939;
    }

    .logo-image {
        width: 80%;
        margin-bottom: 100px;
    }

    .person-container {
        margin-right: 10px;
        margin-left: -50px;
    }

    .person-container img {
        width: 300px;
        height: 300px;
    }

    .menu-item {
        font-size: 1.5rem;
    }

    .cursor {
        width: 25px;
        height: 25px;
    }

    .landing-page-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .landing-page-modal-content {
        background-size: cover;
        background-color: rgb(133, 128, 128);
        width: 550px;
        height: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid rgb(171, 167, 167);
        border-radius: 15px;
    }

    .landing-page-modal-text {
        width: 70%;
        height: 60%;
        color: #ff0000;
        padding: 20px;
        border-radius: 10px;
        overflow-y: auto;
        text-align: center;
        font-size: 16px;
    }

    /* Remove bullet points from lists inside the modal */
    .landing-page-modal-text ul {
        list-style-type: none;
        padding-left: 0;
        margin-left: 100px;
    }

    .landing-page-modal-close-button {
        position: absolute;
        top: 3px;
        right: 3px;
        background: none;
        border: none;
        font-size: 15px;
        cursor: pointer;
        color: white;
    }

    /* Style links inside modal */
    .landing-page-modal-header {
        font-size: 22px;
    }

    .landing-page-modal-text a {
        color: #ff0000;
        text-decoration: none;
        font-size: 20px;
    }

    .landing-page-modal-text a:visited {
        color: #ff0000;
    }

    .landing-page-modal-text a:focus,
    .landing-page-modal-text a:active {
        color: #ff0000;
    }

	.landing-page-modal-text ul li,
	.landing-page-modal-text ul.sub-list li {
		margin-bottom: 25px;
		font-size: 20px;
	}

    .landing-page-modal-text ul.sub-list {
        margin-top: 10px;
    }

}

@media (max-width: 768px) {
    /* Modal overlay to cover the whole screen and center content */
    .content-wrapper {
        margin-left: 50px;
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 55%;
        user-select: none;
    }

    .landing-page-modal-text::-webkit-scrollbar {
        width: 12px;
    }

    .landing-page-modal-text::-webkit-scrollbar-track {
        background: #acacac;
        border-radius: 6px;
    }

    .landing-page-modal-text::-webkit-scrollbar-thumb {
        background: #615f5f;
        border-radius: 6px;
    }

    .landing-page-modal-text::-webkit-scrollbar-thumb:hover {
        background: #3b3939;
    }

    .logo-image {
        width: 90%;
        margin-bottom: 100px;
    }

    .person-container {
        margin-right: 10px;
        margin-left: -50px;
    }

    .person-container img {
        width: 200px;
        height: 200px;
    }

    .menu-item {
        font-size: 1.2rem;
    }
    .menu-item.telegram-offset {
        margin-left: -2px; /* Adjust the exact value as needed */
      }
    .cursor {
        width: 20px;
        height: 20px;
    }

    .landing-page-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .landing-page-modal-content {
        background-size: cover;
        background-color: rgb(133, 128, 128);
        width: 450px;
        height: 290px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid rgb(171, 167, 167);
        border-radius: 15px;
    }

    .landing-page-modal-text {
        width: 70%;
        height: 60%;
        color: #ff0000;
        padding: 20px;
        border-radius: 10px;
        overflow-y: auto;
        text-align: center;
        font-size: 14px;
    }

    /* Remove bullet points from lists inside the modal */
    .landing-page-modal-text ul {
        list-style-type: none;
        padding-left: 0;
        margin-left: 73px;
    }

    .landing-page-modal-close-button {
        position: absolute;
        top: 3px;
        right: 3px;
        background: none;
        border: none;
        font-size: 15px;
        cursor: pointer;
        color: white;
    }

    /* Style links inside modal */
    .landing-page-modal-header {
        font-size: 20px;
    }

    .landing-page-modal-text a {
        color: #ff0000;
        text-decoration: none;
        font-size: 14px;
    }

    .landing-page-modal-text a:visited {
        color: #ff0000;
    }

    .landing-page-modal-text a:focus,
    .landing-page-modal-text a:active {
        color: #ff0000;
    }

	.landing-page-modal-text ul li,
	.landing-page-modal-text ul.sub-list li {
		margin-bottom: 25px;
		font-size: 14px;
	}

    .landing-page-modal-text ul.sub-list {
        margin-top: 10px;
    }
    .cursor {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
    
    .cursor-small {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

}

@media (max-width: 576px) {
    /* Modal overlay to cover the whole screen and center content */
    .content-wrapper {
        margin-left: 50px;
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 55%;
        user-select: none;
    }

    .landing-page-modal-text::-webkit-scrollbar {
        width: 12px;
    }

    .landing-page-modal-text::-webkit-scrollbar-track {
        background: #acacac;
        border-radius: 6px;
    }

    .landing-page-modal-text::-webkit-scrollbar-thumb {
        background: #615f5f;
        border-radius: 6px;
    }

    .landing-page-modal-text::-webkit-scrollbar-thumb:hover {
        background: #3b3939;
    }

    .logo-image {
        width: 100%;
        margin-bottom: 100px;
    }

    .person-container {
        margin-right: 10px;
        margin-left: -50px;
    }

    .person-container img {
        width: 150px;
        height: 150px;
    }

    .menu-item {
        font-size: 1rem;
    }

    .landing-page-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .landing-page-modal-content {
        background-size: cover;
        background-color: rgb(133, 128, 128);
        width: 350px;
        height: 225px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid rgb(171, 167, 167);
        border-radius: 15px;
    }

    .landing-page-modal-text {
        width: 70%;
        height: 60%;
        color: #ff0000;
        padding: 20px;
        border-radius: 10px;
        overflow-y: auto;
        text-align: center;
        font-size: 12px;
    }

    /* Remove bullet points from lists inside the modal */
    .landing-page-modal-text ul {
        list-style-type: none;
        padding-left: 0;
        margin-left: 40px;
    }

    .landing-page-modal-close-button {
        position: absolute;
        top: 3px;
        right: 3px;
        background: none;
        border: none;
        font-size: 15px;
        cursor: pointer;
        color: white;
    }

    /* Style links inside modal */
    .landing-page-modal-header {
        font-size: 20px;
    }

    .landing-page-modal-text a {
        color: #ff0000;
        text-decoration: none;
        font-size: 14px;
    }

    .landing-page-modal-text a:visited {
        color: #ff0000;
    }

    .landing-page-modal-text a:focus,
    .landing-page-modal-text a:active {
        color: #ff0000;
    }

	.landing-page-modal-text ul li,
	.landing-page-modal-text ul.sub-list li {
		margin-bottom: 25px;
		font-size: 14px;
	}

    .landing-page-modal-text ul.sub-list {
        margin-top: 10px;
    }
    .cursor {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
    
    .cursor-small {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

}
