.main-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	position: relative;
}

.filter-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	margin-top: 200px;
	margin-left: -115px;
	width: 100%;
	box-sizing: border-box;
}

/* Container for search elements and category buttons */
.search-container-wrapper {
	position: absolute;
	top: -30px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	width: 100%;
}

.search-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	margin-left: 205px;
}

.category-buttons {
	background: linear-gradient(to bottom, #b03434 0%, #8a2b2b 100%);
	color: #ffffff;
	border: 2px solid #ffffff; 
	padding: 10px 15px;
	border-radius: 20px;
	cursor: pointer;
	margin: 0;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	font-family: "Press Start 2P", sans-serif;
	font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 40px;
	user-select: none;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.category-buttons:hover {
	background: linear-gradient(to bottom, #c34545 0%, #922222 100%); 
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.search-text {
	padding: 8px;
	border-radius: 20px;
	background-color: #fff;
	color: #000000;
	font-family: "Press Start 2P", sans-serif;
	font-size: 13px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	height: 20px;
	width: 240px;
	margin: 0;
	margin-right: 3px;
}

.search-button {
	background: linear-gradient(to bottom, #767777 0%, #5a5a5a 100%);
	color: #ffffff;
	border: 1px solid #6a6a6a; 
	padding: 8px;
	border-radius: 20px;
	font-family: "Press Start 2P", sans-serif;
	font-size: 13px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	height: 40px;
	width: 100px;
	cursor: pointer;
	margin: 0;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.search-button:hover {
	background: linear-gradient(to bottom, #898989 0%, #676767 100%); 
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.primary-buttons-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
	user-select: none;
}
.additional-buttons {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-top: -30px;
	user-select: none;
}
.canvas-toolbar-container {
	display: flex;
	align-items: start;
}
.canvas-frame {
	margin: 0 auto;
	position: relative;
	border: 5px solid #dae7e9;
	background-color: rgb(63, 57, 57);
	border-radius: 5px;
	width: 600px;
	height: 600px;
}
.bottom-frame-buttons {
	position: absolute;
	bottom: -55px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 10px;
	justify-content: center;
	width: 590px;
	user-select: none;
}
.button-toolbar {
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-right: 20px;
}

.primary-button {
	background: linear-gradient(to bottom, #767777 0%, #5e5e5e 100%);
	color: #ffffff;
	border: 1px solid #6d6d6d;
	padding: 12px 16px;
	border-radius: 20px;
	cursor: pointer;
	font-size: 12px;
	margin: 0;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	font-family: "Press Start 2P", sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 185px;
	height: 40px;
	user-select: none;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.primary-button:hover {
	background: linear-gradient(to bottom, #8a8a8a 0%, #636363 100%); 
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.hide-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	margin-bottom: -5px;
}

.modal-content {
	background-color: #767777;
	border: 1px solid #000000;
	border-radius: 15px;
	overflow-y: auto;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	position: absolute;
	bottom: -115px;
	left: 730px;
	transform: translateX(-50%);
	width: 220px;
	height: 720px;
	max-height: 720px;
	display: flex;
	flex-direction: column;
	align-items: center;
	user-select: none;
	color: #ffffff;
	font-size: 13px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
}

.image-selection {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: auto;
	padding-top: 10px;
}

/* Style for individual images */
.image-selection img {
	width: 100px;
	height: auto;
	cursor: pointer;
}

.guide-modal .modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.75);
	z-index: 1050;
	overflow: hidden;
}

.guide-modal .modal-content {
    position: fixed;
    top: 350px;
    left: 50%;
	margin-left: 50px;
    width: 80%;
    max-width: 450px;
    max-height: 450px;
    background: rgb(137, 102, 102, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 1051;
    overflow-y: auto;
}
.modal-h2-align {
	text-align: center;
}
.info-button {
    background: linear-gradient(180deg, #fff 0, #e6e6e6);
    border: 1px solid #ddd;
    border-radius: 30px;
    box-shadow: 0 2px 4px #0000001a;
    color: #000;
    cursor: pointer;
    font-family: Press Start\ 2P, Arial, sans-serif;
	overflow: hidden;
    position: absolute;
    top: -30px;
    left: 50%;
	margin-left: -250px;
    transform: translateX(-50%);
    transition: background-color .3s ease, box-shadow .3s ease;
    z-index: 1501;
    transition: background-color .3s ease, box-shadow .3s ease;
    z-index: 1501;
}

.info-button:hover {
	background: linear-gradient(
		to bottom,
		#f7f7f7 0%,
		#dcdcdc 100%
	); 
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button {
	background: linear-gradient(
		to bottom,
		#ffffff 0%,
		#e6e6e6 100%
	); 
	color: #000000;
	border: 1px solid #ddd;
	padding: 8px;
	border-radius: 20px;
	font-family: "Press Start 2P", Arial, sans-serif;
	font-size: 15px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
	cursor: pointer;
	margin-top: 10px;
	z-index: 1501;
	overflow: hidden;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.close-button:hover {
	background: linear-gradient(
		to bottom,
		#f7f7f7 0%,
		#dcdcdc 100%
	); 
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.text-modal .modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.75);
	z-index: 1050;
	overflow: hidden;
}

.text-modal .modal-content {
    position: fixed;
    top: 350px;
    left: 50%;
	margin-left: 50px;
    width: 80%;
    max-width: 450px;
    max-height: 450px;
    background: rgb(137, 102, 102, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 1051;
    overflow-y: auto;
}

.text-modal h3 {
	margin-bottom: 12px;
	color: #fff;
	font-family: "Press Start 2P", Arial, sans-serif;
	text-align: center;
}

.text-modal button {
	background: linear-gradient(180deg, #fff 0, #e6e6e6);
	border: 1px solid #ddd;
	border-radius: 30px;
	box-shadow: 0 2px 4px #0000001a;
	color: #000;
	cursor: pointer;
	font-family: "Press Start 2P", Arial, sans-serif;
	margin: 10px 0;
	padding: 10px 20px;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	margin: 10px;;
}

.text-modal button:hover {
	background: linear-gradient(to bottom, #f7f7f7 0%, #dcdcdc 100%);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.text-modal input[type="text"] {
	width: 70%;
	padding: 10px;
	margin: 10px auto;
	display: block;
	border-radius: 20px;
	border: 1px solid #ddd;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	font-family: "Press Start 2P", Arial, sans-serif;
	
}

.text-modal label {
	display: flex;
	align-items: center;
	margin: 20px 0;
	color: #fff;
	font-family: "Press Start 2P", Arial, sans-serif;
}

.text-modal label input[type="checkbox"] {
	margin-right: 10px;
}
.add-text-button {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
.text-styling-modal .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1050;
    overflow: hidden;
}

.text-styling-modal .modal-content {
    position: fixed;
    top: 350px;
    left: 50%;
    margin-left: 50px;
    width: 80%;
    max-width: 450px;
    max-height: 350px;
	background: rgba(137, 102, 102, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 1051;
    overflow-y: auto;
}

.text-styling-modal h3 {
    margin-bottom: 12px;
    color: #fff;
    font-family: "Press Start 2P", Arial, sans-serif;
    text-align: center;
}

.text-styling-modal button {
    background: linear-gradient(180deg, #fff 0, #e6e6e6);
    border: 1px solid #ddd;
    border-radius: 30px;
    box-shadow: 0 2px 4px #0000001a;
    color: #000;
    cursor: pointer;
    font-family: "Press Start 2P", Arial, sans-serif;
    margin: 10px 0;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin: 10px;
}

.text-styling-modal button:hover {
    background: linear-gradient(to bottom, #f7f7f7 0%, #dcdcdc 100%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-height: 1024px) and (min-height: 769px) {


    .filter-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 100px;
        margin-left: -115px;
        width: 100%;
        box-sizing: border-box;
    }
    .canvas-frame {
        margin: 0 auto;
        position: relative;
        border: 5px solid #dae7e9;
        background-color: rgb(63, 57, 57);
        border-radius: 5px;
        width: 600px;
        height: 600px;
    }
    .guide-modal .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.75);
        z-index: 1050;
        overflow: hidden;
    }

    .guide-modal .modal-content {
        position: fixed;
        top: 250px;
        left: 50%;
        margin-left: 50px;
        width: 80%;
        max-width: 450px;
        max-height: 450px;
        background: rgb(137, 102, 102, 0.8);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
    }

    .modal-h2-align {
        text-align: center;
    }

    .info-button {
        background: linear-gradient(180deg, #fff 0, #e6e6e6);
        border: 1px solid #ddd;
        border-radius: 30px;
        box-shadow: 0 2px 4px #0000001a;
        color: #000;
        cursor: pointer;
        font-family: Press Start\ 2P, Arial, sans-serif;
        overflow: hidden;
        position: absolute;
        top: -30px;
        left: 50%;
        margin-left: -250px;
        transform: translateX(-50%);
        transition: background-color .3s ease, box-shadow .3s ease;
        z-index: 1501;
        transition: background-color .3s ease, box-shadow .3s ease;
        z-index: 1501;
    }

    .text-modal .modal-content {
        position: fixed;
        top: 250px;
        left: 50%;
        margin-left: 50px;
        width: 80%;
        max-width: 450px;
        max-height: 450px;
        background: rgb(137, 102, 102, 0.8);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
    }



    .text-styling-modal .modal-content {
        position: fixed;
        top: 250px;
        left: 50%;
        margin-left: 50px;
        width: 80%;
        max-width: 450px;
        max-height: 350px;
        background: rgba(137, 102, 102, 0.8);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
    }
}


/* Mobile */
@media only screen and (max-width: 1024px) {
	.App {
		background-attachment: fixed;
		background-size: contain;
		background-position: center;
		background-repeat: repeat;
	}

	.filter-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		margin-top: 100px;
		padding-left: 140px;
		width: 100%;
		box-sizing: border-box;
	}
	.guide-modal .modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.75);
		z-index: 1050;
		overflow: hidden;
	}

	.guide-modal .modal-content {
        top: 260px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 350px;
        max-height: 300px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}

	.info-button {
		overflow: hidden;
		position: absolute;
		top: -25px;
		left: 50%;
		margin-left: -150px;
		transform: translateX(-50%);
	}
	.close-button {
		background: linear-gradient(
			to bottom,
			#ffffff 0%,
			#e6e6e6 100%
		);
		border: 1px solid #ddd;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		margin-top: 0px;
		z-index: 1501;
		overflow-y: auto;
		cursor: pointer;
		background-color: #ffffff;
		font-size: 12px;
		font-family: "Press Start 2P", Arial, sans-serif;
		border-radius: 20px;
		padding: 8px;
	}
	.text-modal .modal-content {
        top: 260px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 300px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}
	.text-styling-modal .modal-content {
        top: 260px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 300px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}
	.canvas-toolbar-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
	}
	.canvas-frame {
		margin: 0 auto;
		position: relative;
		border: 5px solid #dae7e9;
		background-color: rgb(63, 57, 57);
		border-radius: 5px;
		box-shadow: 0 4px 8px rgba(210, 99, 99, 0.1);
		width: 600px;
		height: 600px;
		z-index: 1000;
	}
	canvas {
        max-width: 600px;
        max-height: 600px;
    }
	.bottom-frame-buttons,
	.modal-mobile {
		flex-basis: 33%;
		display: flex;
		bottom: -370px;
		flex-direction: column;
		align-items: center;
		margin-left: -10px;
		padding: 0;
		gap: 5px;
	}

	.primary-buttons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		z-index: 1000;
	}

	.hide-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
	}
	.primary-buttons {
		display: flex;
		flex-direction: column;
		gap: 5px;
		align-items: center;
		justify-content: center;
		z-index: 1000;
		margin-top: -10px;
	}
	.additional-buttons {
		display: flex;
		flex-direction: column;
		gap: 5px;
		align-items: center;
		justify-content: center;
	}

	.button-toolbar {
		display: grid;
		grid-template-columns: 1fr 1fr;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		margin-top: 20px;
		margin-left: -80px;
	}
	.bottom-and-modal {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.color-input {
		z-index: 1500;
	}
	.primary-button {
		background: linear-gradient(to bottom, #767777 0%, #5e5e5e 100%);
		border: 1px solid #6d6d6d;
		padding: 12px 16px;
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
		background-color: #767777;
		color: #ffffff;
		padding: 10px 15px;
		border-radius: 20px;
		cursor: pointer;
		margin: 0;
		font-family: "Press Start 2P", sans-serif;
		font-size: 9px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 40px;
	}
	/* Container for search elements */
	.search-container-wrapper {
		position: absolute;
		top: -30px;
		padding-right: 85px;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.search-container {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3px;
	}

	.category-buttons {
		background: linear-gradient(to bottom, #b03434 0%, #8a2b2b 100%);
		border: 2px solid #b03434; 
		cursor: pointer;
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		background-color: #b03434;
		color: #ffffff;
		padding: 10px 15px;
		border-radius: 20px;
		cursor: pointer;
		margin: 0;
		font-family: "Press Start 2P", sans-serif;
		font-size: 9px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 90px;
		height: 45px;
	}

	.search-text {
		padding: 8px;
		border-radius: 20px;
		background-color: #fff;
		color: #000000;
		font-family: "Press Start 2P", sans-serif;
		font-size: 7px;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		height: 25px;
		width: 105px;
		margin: 0;
	}

	.search-button {
		background: linear-gradient(to bottom, #767777 0%, #5a5a5a 100%);
		border: 1px solid #6a6a6a; 
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		border-radius: 20px;
		background-color: #767777;
		color: #ffffff;
		font-family: "Press Start 2P", sans-serif;
		font-size: 9px;
		height: 30px;
		width: 60px;
		margin: 0;
		margin-top: 5px;
	}
	.modal-mobile {
		position: absolute;
		bottom: -345px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		padding-left: 280px;
	}
	.modal-content {
		background-color: #767777;
		border: 1px solid #888;
		border-radius: 15px;
		overflow-y: auto;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		position: absolute;
		left: calc(90%);
		top: -330px;
		width: 125px;
		max-height: 353px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 10px;
		position: absolute;
	}
	.image-selection {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow-x: auto;
		padding-top: 10px;
	}

	.image-selection img {
		width: 100px;
		height: auto;
		cursor: pointer;
	}
}
@media only screen and (max-height: 769px) {
    .App {
		background-attachment: fixed;
		background-size: contain;
		background-position: center;
		background-repeat: repeat;
	}

	.filter-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		margin-top: 100px;
		padding-left: 140px;
		width: 100%;
		box-sizing: border-box;
	}
	.guide-modal .modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.75);
		z-index: 1050;
		overflow: hidden;
	}

	.guide-modal .modal-content {
        top: 260px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 300px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}

	.info-button {
		overflow: hidden;
		position: absolute;
		top: -25px;
		left: 50%;
		margin-left: -150px;
		transform: translateX(-50%);
	}
	.close-button {
		background: linear-gradient(
			to bottom,
			#ffffff 0%,
			#e6e6e6 100%
		);
		border: 1px solid #ddd;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		margin-top: 0px;
		z-index: 1501;
		overflow-y: auto;
		cursor: pointer;
		background-color: #ffffff;
		font-size: 12px;
		font-family: "Press Start 2P", Arial, sans-serif;
		border-radius: 20px;
		padding: 8px;
	}
	.text-modal .modal-content {
        top: 260px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 300px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}
	.text-styling-modal .modal-content {
        top: 260px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 300px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}
	.canvas-toolbar-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
	}
	.canvas-frame {
		margin: 0 auto;
		position: relative;
		border: 5px solid #dae7e9;
		background-color: rgb(63, 57, 57);
		border-radius: 5px;
		box-shadow: 0 4px 8px rgba(210, 99, 99, 0.1);
		width: 600px;
		height: 600px;
		z-index: 1000;
	}
	canvas {
        max-width: 600px;
        max-height: 600px;
    }
	.bottom-frame-buttons,
	.modal-mobile {
		flex-basis: 33%;
		display: flex;
		bottom: -370px;
		flex-direction: column;
		align-items: center;
		margin-left: -10px;
		padding: 0;
		gap: 5px;
	}

	.primary-buttons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		z-index: 1000;
	}

	.hide-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
	}
	.primary-buttons {
		display: flex;
		flex-direction: column;
		gap: 5px;
		align-items: center;
		justify-content: center;
		z-index: 1000;
		margin-top: -10px;
	}
	.additional-buttons {
		display: flex;
		flex-direction: column;
		gap: 5px;
		align-items: center;
		justify-content: center;
	}

	.button-toolbar {
		display: grid;
		grid-template-columns: 1fr 1fr;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		margin-top: 20px;
		margin-left: -80px;
	}
	.bottom-and-modal {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.color-input {
		z-index: 1500;
	}
	.primary-button {
		background: linear-gradient(to bottom, #767777 0%, #5e5e5e 100%);
		border: 1px solid #6d6d6d;
		padding: 12px 16px;
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
		background-color: #767777;
		color: #ffffff;
		padding: 10px 15px;
		border-radius: 20px;
		cursor: pointer;
		margin: 0;
		font-family: "Press Start 2P", sans-serif;
		font-size: 9px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 40px;
	}
	/* Container for search elements */
	.search-container-wrapper {
		position: absolute;
		top: -30px;
		padding-right: 85px;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.search-container {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3px;
	}

	.category-buttons {
		background: linear-gradient(to bottom, #b03434 0%, #8a2b2b 100%);
		border: 2px solid #b03434; 
		cursor: pointer;
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		background-color: #b03434;
		color: #ffffff;
		padding: 10px 15px;
		border-radius: 20px;
		cursor: pointer;
		margin: 0;
		font-family: "Press Start 2P", sans-serif;
		font-size: 9px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 90px;
		height: 45px;
	}

	.search-text {
		padding: 8px;
		border-radius: 20px;
		background-color: #fff;
		color: #000000;
		font-family: "Press Start 2P", sans-serif;
		font-size: 7px;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		height: 25px;
		width: 105px;
		margin: 0;
	}

	.search-button {
		background: linear-gradient(to bottom, #767777 0%, #5a5a5a 100%);
		border: 1px solid #6a6a6a; 
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		border-radius: 20px;
		background-color: #767777;
		color: #ffffff;
		font-family: "Press Start 2P", sans-serif;
		font-size: 9px;
		height: 30px;
		width: 60px;
		margin: 0;
		margin-top: 5px;
	}
	.modal-mobile {
		position: absolute;
		bottom: -345px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		padding-left: 280px;
	}
	.modal-content {
		background-color: #767777;
		border: 1px solid #888;
		border-radius: 15px;
		overflow-y: auto;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		position: absolute;
		left: calc(90%);
		top: -330px;
		width: 125px;
		max-height: 353px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 10px;
		position: absolute;
	}
	.image-selection {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow-x: auto;
		padding-top: 10px;
	}

	.image-selection img {
		width: 100px;
		height: auto;
		cursor: pointer;
	}
}
@media only screen and (max-width: 450px) {
	.canvas-frame {
		width: 97.5%;
		max-width: 1000px;
		height: auto;
	}
	.guide-modal .modal-content {
        top: 205px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 290px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}

	.info-button {
		background: linear-gradient(
			to bottom,
			#ffffff 0%,
			#e6e6e6 100%
		); 
        margin-left: 225px;
        margin-top: 480px;
		position: absolute;
	}
	.close-button {
		font-size: 8px;
	}
	.text-modal .modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.75);
		z-index: 1050;
		overflow: hidden;
	}
	
	.text-modal .modal-content {
        top: 205px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 300px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}
	
	.text-modal h3 {
		margin-bottom: 12px;
		color: #fff;
		font-family: "Press Start 2P", Arial, sans-serif;
		text-align: center;
		font-size: 12px;
	}
	
	.text-modal button {
		background: linear-gradient(180deg, #fff 0, #e6e6e6);
		border: 1px solid #ddd;
		border-radius: 30px;
		box-shadow: 0 2px 4px #0000001a;
		color: #000;
		cursor: pointer;
		font-family: "Press Start 2P", Arial, sans-serif;
		font-size: 10px;
		margin: 10px 0;
		padding: 10px 20px;
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		margin: 5px;
	}
	
	.text-modal button:hover {
		background: linear-gradient(to bottom, #f7f7f7 0%, #dcdcdc 100%);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	}
	
	.text-modal input[type="text"] {
		width: 70%;
		padding: 10px;
		margin: 10px auto;
		display: block;
		border-radius: 20px;
		border: 1px solid #ddd;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		font-family: "Press Start 2P", Arial, sans-serif;
		font-size: 10px;
	}
	
	.text-modal label {
		display: flex;
		align-items: center;
		margin: 20px 0;
		color: #fff;
		font-family: "Press Start 2P", Arial, sans-serif;
	}
	
	.text-modal label input[type="checkbox"] {
		margin-right: 10px;
	}
	.add-text-button {
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	.text-styling-modal .modal-content {
        top: 205px;
        left: 50%;
        margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 300px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
    }

    .text-styling-modal h3 {
        font-size: 12px;
    }

    .text-styling-modal button {
        font-size: 10px;
        margin: 5px;
    }
}
@media only screen and (max-width: 375px) {
	.canvas-frame {
		width: 97.5%;
		max-width: 1000px;
		height: auto;
	}
	.guide-modal .modal-content {
        top: 185px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 290px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}

	.info-button {
		background: linear-gradient(
			to bottom,
			#ffffff 0%,
			#e6e6e6 100%
		); 
        margin-left: 215px;
        margin-top: 425px;
		position: absolute;
	}
	.close-button {
		font-size: 10px;
	}
	.text-modal .modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.75);
		z-index: 1050;
		overflow: hidden;
	}
	
	.text-modal .modal-content {
        top: 185px;
        left: 50%;
		margin-left: 5px;
        transform: translateX(-50%);
        width: 80%;
        max-width: 250px;
        max-height: 290px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        z-index: 1051;
        overflow-y: auto;
	}
	
	.text-modal h3 {
		margin-bottom: 12px;
		color: #fff;
		font-family: "Press Start 2P", Arial, sans-serif;
		text-align: center;
		font-size: 10px;
	}
	
	.text-modal button {
		background: linear-gradient(180deg, #fff 0, #e6e6e6);
		border: 1px solid #ddd;
		border-radius: 30px;
		box-shadow: 0 2px 4px #0000001a;
		color: #000;
		cursor: pointer;
		font-family: "Press Start 2P", Arial, sans-serif;
		font-size: 8px;
		margin: 10px 0;
		padding: 10px 20px;
		transition: background-color 0.3s ease, box-shadow 0.3s ease;
		margin: 5px;
	}
	
	.text-modal button:hover {
		background: linear-gradient(to bottom, #f7f7f7 0%, #dcdcdc 100%);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	}
	
	.text-modal input[type="text"] {
		width: 70%;
		padding: 10px;
		margin: 10px auto;
		display: block;
		border-radius: 20px;
		border: 1px solid #ddd;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		font-family: "Press Start 2P", Arial, sans-serif;
		font-size: 8px;
	}
	
	.text-modal label {
		display: flex;
		align-items: center;
		margin: 20px 0;
		color: #fff;
		font-family: "Press Start 2P", Arial, sans-serif;
	}
	
	.text-modal label input[type="checkbox"] {
		margin-right: 10px;
	}
	.add-text-button {
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	.text-styling-modal .modal-content {
        top: 185px;
        max-width: 250px;
        max-height: 290px;
    }

    .text-styling-modal h3 {
        font-size: 12px;
    }

    .text-styling-modal button {
        font-size: 10px;
        margin: 5px;
    }
}
